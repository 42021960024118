/**
 * returns an array of strings representing the street name and city of user
 * based google places api
 * @param {String} text the address obtained from google places api
 *
 */
export const parseLocation = (text) => {
  let split = text.trim().split(',');

  if (split.length > 1) {
    let zip = split[2].trim().split(' ')[1];
    console.log('split');
    console.log(split[2].split(' '));
    return { addressLine1: split[0], city: split[1], zip };
  }
  return null;
};
