import CalendarGrid from './CalendarGrid';
import CalendarHeader from './CalendarHeader';

function CalendarRenderer({
  onDayClick,
  monthTracker,
  calMan,
  callback,
  therapist,
  selectedDay,
}) {
  let currentMonthDays = calMan.getCalendarDays();
  let fillerDays = calMan.calcFillerDays();

  return (
    <div className="calendar">
      <CalendarHeader
        monthName={calMan.getMonthName()}
        monthTracker={monthTracker}
        callback={callback}
      ></CalendarHeader>
      <CalendarGrid
        fillerDays={fillerDays}
        currentMonthDays={currentMonthDays}
        callback={onDayClick}
        therapist={therapist}
        calMan={calMan}
        selectedDay={selectedDay}
      ></CalendarGrid>
    </div>
  );
}

export default CalendarRenderer;
