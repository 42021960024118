import React, { useEffect } from 'react';
import {
  autoCompleteAPI,
  searchLocationAPI,
} from '../../api/fetchRequests/appointmentRequests.js';

export const SearchBar = ({
  setResults,
  setText,
  text,
  selected,
  setSelected,
}) => {
  /**
   * This changes the search field text per key stroke and updates
   * the selected state to false.
   *
   * @param {Event} e search field event
   */
  const onChange = (e) => {
    let newText = e.target.value;
    if (newText === '') setResults('');
    setText(newText);
    setSelected(false);
  };

  /**
   * Sets the result state to a list of addresses obtained by the google api
   * from the input "e".
   *
   * @param {String} e the string that will be used by google to search for addresses
   */
  const searchLocation = async (e) => {
    let results = await searchLocationAPI(e);
    if (Object.keys(results).length > 0) {
      let arr = results.places.map((obj) => {
        return { address: obj.formattedAddress, id: obj.id };
      });
      setResults(arr);
    }
  };
  /**
   * Returns a list of addresses given {input:string} object that will be passed to
   * google autocomplete api.
   *
   * @param {Object} postBody Object containing a string to search with google api
   * @returns
   */
  const autoComplete = async (postBody) => {
    let results = await autoCompleteAPI(postBody);
    let arr = [];

    if (results.suggestions != null) {
      results.suggestions.forEach((suggestion) => {
        arr.push({
          address: suggestion.placePrediction.text.text,
          id: suggestion.placePrediction.placeId,
        });
      });
    }
    return arr;
  };
  useEffect(() => {
    if (text !== '' && !selected) {
      let postBody = {
        input: text,
      };
      autoComplete(postBody).then((res) => {
        setResults(res);
      });
    }
  }, [text]);
  return (
    <div className="input-wrapper">
      <input
        placeholder="Enter Address"
        value={text}
        onChange={onChange}
      ></input>

      <button onClick={(e) => searchLocation(text)}>search</button>
    </div>
  );
};
