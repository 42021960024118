/**
 * This file serves as an intermediary between route setting and page styling/rendering.
 * It contains functions to render pages based on options logic.
 */

import SingleSelector from '../../templates/OptionSelectionPages/SingleSelection';
import DoubleSelector from '../../templates/OptionSelectionPages/DoubleSelection';
import { useNavigate } from 'react-router-dom';

/**
 * Renders a DoubleSelector component.
 * This function is called when there are two options to be displayed.
 *
 * @param {Array} optionsContainer - An array containing two sets of options.
 * @param {Object} [{ navigate = null }={}] - Object containing the 'navigate' function for navigation. Default is null.
 * @returns {JSX.Element} - A JSX element of the DoubleSelector component.
 */
const getDoubleSelectorPage = (optionsContainer, { navigate = null } = {}) => {
  return (
    <DoubleSelector optionsParcel={optionsContainer} executeNavigation={navigate}/>
  );
};

/**
 * Renders a SingleSelector component.
 * This function is called when there is only one option to be displayed.
 *
 * @param {Object} optionsContainer - An object containing the single set of options.
 * @param {Object} [{ navigate = null }={}] - Object containing the 'navigate' function for navigation. Default is null.
 * @returns {JSX.Element} - A JSX element of the SingleSelector component.
 */
const getSingleSelectorPage = (optionsContainer, { navigate = null } = {}) => {
  return (
    <SingleSelector optionsParcel={optionsContainer} executeNavigation={navigate} />
  );
};

/**
 * Component for rendering the correct option selector based on the provided options.
 * This component decides to render either SingleSelector or DoubleSelector 
 * based on the number of options provided in the optionsContainer.
 *
 * @param {Array|Object} optionsContainer - Array or Object containing the set(s) of options.
 * @param {string} linksTo - The path to navigate to upon executing navigation.
 * @returns {JSX.Element} - A JSX element of either SingleSelector or DoubleSelector component.
 * @throws {Error} - Throws an error if optionsContainer is undefined or if the number of options is neither 1 nor 2.
 */
const OptionSelectorPage = ({ optionsContainer, linksTo, onSubmit, exitPath}) => {
  const navigate = useNavigate()
  
  const handleSubmission = () => {
    if (linksTo === exitPath) {
      navigate(exitPath);
    } else {
      onSubmit(linksTo);
    }
  }
  
  // Conditional rendering based on the number of options
  if (optionsContainer.length === 2) {
    return getDoubleSelectorPage(optionsContainer, { navigate: handleSubmission });
  } else if (optionsContainer.length === 1) {
    return getSingleSelectorPage(optionsContainer[0], { navigate: handleSubmission });
  } else if (!optionsContainer) {
    throw new Error("undefined options");
  } else {
    throw new Error("At most 2 options can share a single path");
  }      
};

export default OptionSelectorPage;
