import ButtonGrid from './ButtonGrid';
import DropDown from './DropDown';

function TimeSection({ times, title, callback, activeIndex }) {
  return (
    <section>
      <DropDown title={title}></DropDown>
      <ButtonGrid
        times={times}
        callback={callback}
        activeIndex={activeIndex}
      ></ButtonGrid>
    </section>
  );
}

export default TimeSection;
