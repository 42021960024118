import React from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutForm from '../components/depositCollection/checkoutForm';
import {
  getAllUserSelections,
  getUserSelection,
} from '../utils/persistence/dataPersistence';
import NameFields from '../templates/NameFields';
import CheckboxWithLabel from '../components/inputFields/CheckboxWithLabel';

const CollectDeposit = ({ nextPath }) => {
  const data = getAllUserSelections();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(nextPath);
  };

  return (
    <div>
      {/* <NameFields /> */}
      {/* <CheckboxWithLabel label="By continuing you accept our terms of service" /> */}
      <CheckoutForm userSelections={data} />
    </div>
  );
};

export default CollectDeposit;
