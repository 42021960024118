import React, { useState, useEffect } from 'react';
import { ProductLister } from '../templates/FormComponents/OptionLister';
import { useNavigate } from 'react-router-dom';
import { getAllUserSelections } from '../utils/persistence/dataPersistence';
import CheckboxWithLabel from '../components/inputFields/CheckboxWithLabel';
import { questionaireURL } from '../api/urls';

function objectToQueryString(obj) {
    const flatten = (obj, prefix = '') => {
        return Object.keys(obj).reduce((acc, key) => {
            const pre = prefix.length ? `${prefix}[${key}]` : key;
            const value = obj[key];
            
            if (Array.isArray(value)) {
                value.forEach((v, i) => {
                    acc.push(`${pre}[${i}]=${encodeURIComponent(v)}`);
                });
            } else if (typeof value === 'object' && value !== null) {
                acc.push(...flatten(value, pre));
            } else {
                acc.push(`${pre}=${encodeURIComponent(value)}`);
            }
            
            return acc;
        }, []);
    };
    
    return flatten(obj).join('&');
}
  

const ServiceOverview = ({ nextPath }) => {
    const [servicesData, setServicesData] = useState([]);
    const [serviceSubtotal, setSubtotal] = useState();
    const [depositAmount, setDeposit] = useState(50);
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate(nextPath);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = getAllUserSelections();
            const queryString = objectToQueryString(data);
            const getRequestUrl = `${questionaireURL}/selection-data?${queryString}`;

            // console.log(getRequestUrl);

            try {
                const response = await fetch(getRequestUrl, {
                    headers: {
                        Accept: 'application/json',
                    },
                });

                const text = await response.text(); // Get raw response text
                // console.log('Raw response:', text); // Log the raw response
                
                // Attempt to parse JSON only if response is not empty and is valid JSON
                const rawServiceData = text ? JSON.parse(text) : {};
                // console.log(data.selectedChoices);

                setServicesData(rawServiceData.selectedChoices);
                setSubtotal(rawServiceData.totalCost);
                setDeposit(rawServiceData.depositAmount)
            } catch (error) {
                console.error('Failed to retrieve user selections:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='dark-mode full-height'>
            <ProductLister data={servicesData} />
            <section style={{paddingTop: '12px'}}>
                {/* subtotal */}
                <h2>Subtotal</h2>
                <h3>${serviceSubtotal}</h3>
                
                {/* total */}
                {/* due today/Deposit */}
                <h2>Due TODAY</h2>
                <h3>${depositAmount}</h3>

                <h3>Due Later</h3>
                <h4>${serviceSubtotal - depositAmount}</h4>
            </section>
            {/* <CheckboxWithLabel label="By continuing your waive liability" /> */}
            <button onClick={handleNavigation}>Secure Spot</button>
        </div>
    );
};

export default ServiceOverview;
