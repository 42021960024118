import React from 'react';
import StackedForm from '../FormComponents/StackedForm';

import { saveQuestionnaireSelection, getUserSelection } from '../../utils/persistence/dataPersistence';

import { openOptionsParcel } from '../../utils/parsers/questionaireParser';

const SingleSelector = ({ optionsParcel, executeNavigation }) => {
    
    const optionObject = openOptionsParcel(optionsParcel);
    
    const optionId = optionObject.id;
    const optionChoices = optionObject.choices;

    const onSubmit = (choice) => {
        executeNavigation();
        saveQuestionnaireSelection(choice, optionId);
    }

    // console.log(optionObject);
    
    return (
        <div className="App dark-mode">
            <StackedForm 
                optionData={optionChoices ? optionChoices : []}
                onSubmissionCallback={onSubmit}
                multiSelect={optionObject.settings?.multi_select}
                isRequired={optionObject.settings?.required}
            />
        </div>
    );
}

export default SingleSelector;
