import React, { useEffect, useState } from 'react';
import StackingOption from './Selection/StackingOption';

const StackedForm = ({ optionData, onSubmissionCallback, multiSelect=false, isRequired=false }) => {  
    const [selectedOptions, setSelections] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(isRequired);

    const handleSubmission = (event) => {
        event.preventDefault();
        onSubmissionCallback(selectedOptions);
    };

    useEffect(()=>{
        if(isRequired){
            const isValidSelection =  selectedOptions.length > 0;
            setIsSubmitDisabled(!isValidSelection);
        }
    }, [selectedOptions]);
    
    const handleSelection = (choiceId) => {
        if (multiSelect) {
            if (selectedOptions.includes(choiceId)) {
                setSelections(selectedOptions.filter(opt => opt !== choiceId));
            } else {
                setSelections([...selectedOptions, choiceId]);
            }
        } else {
            setSelections([choiceId]);
        }
    };

    return (
        <div className='page-width'>
            <form onSubmit={handleSubmission}>
                {optionData && (
                    optionData.map((choice, index) => (
                        <StackingOption
                            key={choice.id}
                            title={choice.title}
                            description={choice.description}
                            price={choice.price}
                            onSelected={ () => handleSelection(choice.id) }
                            isSelected={selectedOptions.includes(choice.id)}
                        />
                    ))
                )}

                <button type="submit" disabled={isSubmitDisabled}>Submit</button>
            </form>
        </div>
    );
};

export default StackedForm;
