import { useEffect, useState } from 'react';
import './calendar.css';
import TimeContainer from './timeSelection/TimesContainer';
import CalendarRenderer from './calendar/CalendarRenderer';
import CalendarManager from './calendar/CalendarManager';
import {
  convertAmPmToHoursMins,
  pstdayjs,
} from '../utils/calendar/calendarTools';
import Therapist from './objects/Therapist';

import { mapAppointmentLength } from '../utils/tools/tools.js';
import { getUserSelection } from '../utils/persistence/dataPersistence.js';

export const PST = 'America/Los_Angeles';
// EXPLANATION
// I use the selectedDay state to pick which day of the month we are looking at
// this will be used to index the appointments array to see which times are already taken
// which will feed into unavailableTimes of TimeContainer

function CalendarForm({ therapist, handleRoute }) {
  const [monthTracker, setMonthTracker] = useState(0);
  const [calMan, setCalMan] = useState(
    new CalendarManager(pstdayjs().toDate())
  );
  const [activeIndex, setActiveIndex] = useState('0');

  const [currentAppointmentLength, setAppointmentLength] = useState(0);
  const [selectedDay, setSelectedDay] = useState(-1);

  let selectedDate = pstdayjs(calMan.viewingDate).set('date', selectedDay);

  useEffect(() => {
    setSelectedDay(
      therapist.getSoonestAvailableWorkday(pstdayjs(calMan.viewingDate))
    );

    const fetchData = async () => {
      try {
        const data = await getUserSelection('questionnaire_selections');
        const duration = await mapAppointmentLength(data);
        setAppointmentLength(duration);
      } catch (err) {
        console.log('Error:', err);
      }
    };

    fetchData();

  }, []);

  function callback(offset) {
    setActiveIndex('0');
    if (offset === -1) {
      calMan.previousViewingMonth();
    } else {
      calMan.nextViewingMonth();
    }
    setCalMan(new CalendarManager(calMan.viewingDate));
    setMonthTracker(monthTracker + offset);
    setSelectedDay(
      therapist.getSoonestAvailableWorkday(pstdayjs(calMan.viewingDate))
    );
  }
  function onDayClick(newDay) {
    setSelectedDay(newDay);
    setCalMan(new CalendarManager(calMan.viewingDate));
    setActiveIndex('0');
  }

  function onSubmit(e) {
    e.preventDefault();
    let { hours, minutes } = therapist.convertAmPmToHoursMins(activeIndex);
    let newDate = pstdayjs()
      .set('year', calMan.viewingDate.getFullYear())
      .set('month', calMan.viewingDate.getMonth())
      .set('date', selectedDay)
      .set('hour', hours)
      .set('minute', minutes)
      .set('second', 0)
      .set('millisecond', 0);
    handleRoute(newDate);
  }

  function handleClick(index) {
    console.log('here');
    setActiveIndex(index);
  }

  let canWorkToday = therapist.checkIfCanWorkThisDay(selectedDate.toDate());
  let timesToDisplay = therapist.getOpenTimesForDay(
    selectedDate.toDate(),
    currentAppointmentLength
  );

  return (
    <form onSubmit={onSubmit}>
      <CalendarRenderer
        onDayClick={onDayClick}
        monthTracker={monthTracker}
        calMan={calMan}
        callback={callback}
        therapist={therapist}
        selectedDay={selectedDay}
      ></CalendarRenderer>
      <TimeContainer
        activeIndex={activeIndex}
        handleClick={handleClick}
        canWorkToday={canWorkToday}
        timesToDisplay={timesToDisplay}
      ></TimeContainer>
      {activeIndex !== '0' && selectedDay !== -1 ? (
        <input type="submit"></input>
      ) : (
        <input disabled={true} type="submit"></input>
      )}
    </form>
  );
}

export default CalendarForm;
