import TimeSection from './TimeSection';

/**
 *
 * @param {*} param0
 * @returns
 */
function TimeContainer({
  activeIndex,
  handleClick,
  canWorkToday,
  timesToDisplay,
}) {
  if (!canWorkToday) {
    return null;
  }

  const sectionNames = ['Morning', 'Afternoon', 'Evening'];

  let remainingTimeSlots = splitAppTimesIntoSections(timesToDisplay);

  let areAppsAvailable = remainingTimeSlots.flat().length > 0;
  return (
    <div className="time-section">
      {areAppsAvailable ? (
        remainingTimeSlots.map((arr, index) => {
          return (
            arr.length > 0 && (
              <TimeSection
                key={sectionNames[index]}
                callback={handleClick}
                activeIndex={activeIndex}
                times={arr}
                title={sectionNames[index]}
              ></TimeSection>
            )
          );
        })
      ) : (
        <h2>No Available Appointments</h2>
      )}
    </div>
  );
}

function splitAppTimesIntoSections(originalTimes) {
  let morningArr = [];
  let afternoonArr = [];
  let eveningArr = [];

  originalTimes.forEach((time) => {
    let hour = parseInt(time.split(':')[0]);
    if (time.includes('am')) {
      morningArr.push(time);
    } else if (hour % 12 < 5) {
      afternoonArr.push(time);
    } else {
      eveningArr.push(time);
    }
  });
  return [morningArr, afternoonArr, eveningArr];
}

export default TimeContainer;
