import leftArrow from '../../assets/left-arrow.png';
import rightArrow from '../../assets/right-arrow.png';
function CalendarHeader({ monthName, monthTracker, callback }) {
  let arrow;
  let alt = '';
  let offset;
  if (monthTracker == 0) {
    arrow = rightArrow;
    alt = 'Right arrow to check next months availability';
    offset = 1;
  } else {
    arrow = leftArrow;
    alt = 'Left arrow to check previous months availability';
    offset = -1;
  }
  return (
    <div className="calendar-header">
      <h3>{monthName}</h3>
      <img
        src={arrow}
        alt={alt}
        onClick={() => {
          callback(offset);
        }}
      />
    </div>
  );
}

export default CalendarHeader;
