import React, { useState } from 'react';
import { SearchBar } from './searchBar.jsx';
import { SearchResults } from './searchResults.jsx';
export const SearchBarContainer = () => {
  const [results, setResults] = useState([]);
  const [text, setText] = useState('');
  const [selected, setSelected] = useState(false);
  return (
    <div className="search-bar-container">
      <SearchBar
        setResults={setResults}
        text={text}
        setText={setText}
        setSelected={setSelected}
        selected={selected}
      ></SearchBar>
      {results !== '' && (
        <SearchResults
          results={results}
          setText={setText}
          setResults={setResults}
          setSelected={setSelected}
        ></SearchResults>
      )}
    </div>
  );
};
