import React, { useState, useEffect } from 'react';

const ProductLine = ({product}) => {
  return (
    <ul style={{paddingLeft: '1em'}}>
      {product && <li>{product.title}</li>}
      {product && <li>${product.price}</li>}
    </ul>
  );
}

export const ProductLister = ({ data }) => {
  const [productData, setProductData] = useState({
    option1: [],
    option2: [],
    addOns: [],
    flags: []
  });


  /** IGNORE THIS: USED FOR TESTING PURPOSES */
  if (productData.option1 > 0) {
    console.log("Option1", productData.option1[0].title);
    // console.log("Option2", option2[0]);
    // console.log("add_ons", addOns);
    // console.log("flags", flags);
  }
  /** END IGNORE */
  

  useEffect(() => {
    if (data.length > 0) {
      setProductData({
        option1: data[0] || [],
        option2: data[1] || [],
        addOns: data[2] || [],
        flags: data[3] || []
      });
    }
  }, [data]);

  return (
    <section>
      <ul>
        <h4>Healing Path</h4>
        {<ProductLine product={productData.option1[0]} />}
      </ul>

      <ul style={{ paddingTop: '8px' }}>
        <h4>Personalization Level</h4>
        {<ProductLine product={productData.option2[0]} />}
      </ul>

      <ul style={{ paddingTop: '8px' }}>
        <h4>Add-Ons</h4>
        {productData.addOns.map((addOn, i) => {
          return (
            <div key={i} style={{ paddingTop: '8px' }}>
              <ProductLine product={addOn} />
            </div>
          );
        })}
      </ul>

      <ul style={{ paddingTop: '8px' }}>
        <h4>Flags</h4>
        {productData.flags.map((flag, i) => {
          return (
            <div key={i} style={{ paddingTop: '8px' }}>
              <ProductLine product={flag} />
            </div>
          );
        })}
      </ul>
    </section>
  );
};