import React, { useState, useEffect } from 'react';
import './Theme/App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Questionnaire from './routes/Questionaire/routeSetter';
import useOptions from './utils/questionaireLoader';
import AppointmentDetails from './routes/AppointmentDetails';
import CollectDeposit from './routes/DepositCollection';
import ConfirmationRouter from './routes/FinalConfirmation';
import ServiceOverview from './routes/ServiceAgreement';

const route1 = '/';
const route2 = '/scheduling';
const route3 = '/waives-docs';
const route4 = '/make-it-official';
const route5 = '/confirmation';

const App = () => {
  console.log('EMT: 0.1.0');
  // Using custom hook to load configuration data
  const { config, loading, error } = useOptions();

  // Authentication state (currently hardcoded to false)
  const isAuthenticated = false;

  // Show a loading message while the configuration is being loaded
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show an error message if there was an error loading the configuration
  if (error) {
    return <div>Fatal error: {error.message}</div>;
  }

  // Main app content
  return (
    <Router basename="/">
      <Routes>
        <Route
          path={route1}
          element={
            <Questionnaire
              auth={isAuthenticated}
              config={config}
              nextPath={route2}
            />
          }
        />
        <Route
          path={route2}
          element={<AppointmentDetails nextPath={route3} />}
        />
        <Route path={route3} element={<ServiceOverview nextPath={route4} />} />
        <Route path={route4} element={<CollectDeposit nextPath={route5} />} />
        <Route path={route5} element={<ConfirmationRouter />} />
      </Routes>
    </Router>
  );
};

export default App;
