/**
 * Failed - The payment attempt was unsuccessful, possibly due to insufficient funds, incorrect card details, or other issues.
 * When a payment fails, the order is marked as "Not Completed" or "Declined" and the amount is not transferred to the
 * merchant's account. Failed transactions appear in Stripe's Failed payments report.
 *
 * Incomplete - The payment process was started but not completed. This could happen if the customer abandons the checkout
 * session or doesn't select Stripe as the payment method.
 */

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  createAppointment,
  createNewClientAPI,
  isAppointmentTimeOpen,
} from '../api/fetchRequests/appointmentRequests.js';
import {
  getAllUserSelections,
  saveSensitiveUserData,
} from '../utils/persistence/dataPersistence.js';
import { paymentURL } from '../api/urls.js';
import { mapAppointmentLength } from '../utils/tools/tools.js';

const ConfirmationRouter = () => {
  const [status, setStatus] = useState(null);
  const [customerData, setCustomerData] = useState('');
  const [responseReceived, setResponseReceived] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    if (status == null) {
      fetch(`${paymentURL}/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerData(data.customer_details);
        });
    } else if (status === 'complete') {
      // API call to make appointment and new client
      let selections = getAllUserSelections();
      let address = JSON.parse(selections.userAddress);
      saveSensitiveUserData(address.zip, 'zipcode');
      saveSensitiveUserData(address.addressLine1, 'street');
      saveSensitiveUserData(address.city, 'city');
      mapAppointmentLength(selections.questionnaire_selections)
        .then((duration) => {
          return isAppointmentTimeOpen(selections.date, duration, 1);
        })
        .then((data) => {
          if (data.status === 400) throw new Error('Cant make appointment');
          return;
        })
        .then(() => {
          return createNewClientAPI(
            customerData.name,
            customerData.email,
            customerData.phone,
            address.zip,
            address.addressLine1,
            address.city
          );
        })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          createAppointment(data[0].client_id, 1);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setResponseReceived(true);
        });
    }
  }, [status]);

  if (status === 'open') {
    return <Navigate to={`/`} />;
  }

  if (status === 'complete') {
    if (!responseReceived) {
      return <p>Creating appointment loading...</p>;
    } else {
      return (
        <>
          {!error ? (
            <section id="success">
              <p>
                We appreciate your business! A confirmation email will be sent
                to {customerData.email}. If you have any questions, please email{' '}
                <a href="mailto:orders@example.com">orders@example.com</a>.
              </p>
            </section>
          ) : (
            <section id="error">
              <p>
                error creating appointment contact this number for further help
              </p>
            </section>
          )}
        </>
      );
    }
  } else {
    return (
      <div>
        <div>Unable to process payment. Try again?</div>
        <button>Retry</button>
      </div>
    );
  }
};

export default ConfirmationRouter;
