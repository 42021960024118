import React from 'react';
import PropTypes from 'prop-types';

const StackingOption = ({ imageSrc, title, description, price, onSelected, isSelected }) => {
  return (
    <div className={`option-item ${isSelected ? 'selected' : ''}`} onClick={onSelected}>
      
      { imageSrc ? <img className='stacking-option__image' src={imageSrc} alt={title} /> : ''}

      <div className='selectable-item stacking-option__content'>
        <h3>{title} - ${price}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default StackingOption;
