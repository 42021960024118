import React from 'react';
import {
  dataNames,
  saveSensitiveUserData,
} from '../../utils/persistence/dataPersistence.js';
import { parseLocation } from '../../utils/parsers/locationParser.js';
import { searchLocationId } from '../../api/fetchRequests/appointmentRequests.js';

export const SearchResults = ({
  results,
  setText,
  setResults,
  setSelected,
}) => {
  /**
   * Stores location data by getting formatted address from the google api to be used to
   * get zip,city and address data consistently.
   *
   * @param {INTEGER} id The google places id obtained from google
   */
  const storeLocation = async (id) => {
    // let arr = parseLocation(result);

    let location = await searchLocationId(id);
    let parsedLocation = parseLocation(location.formattedAddress);
    if (parsedLocation) {
      saveSensitiveUserData(JSON.stringify(parsedLocation), 'userAddress');
      setText(location.formattedAddress);
      setResults([]);
      setSelected(true);
    }
    // TODO WHAT HAPPENS WHEN THE USER HITS A NON VALID ENTRY
    // FOR EXAMPLE IT DOESN'T HAVE TWO COMMA SEPARATED VALUES
    // if (typeof arr != 'string') {
    //   saveSensitiveUserData(arr[0], dataNames.STREET);
    //   saveSensitiveUserData(arr[1], dataNames.CITY);
    // }
  };

  let list = results.map((res) => {
    return (
      <div
        className="search-result"
        onClick={(e) => storeLocation(res.id)}
        key={res.id}
        key-prop={res.id}
      >
        {res.address}
      </div>
    );
  });
  return <div className="results">{list}</div>;
};
