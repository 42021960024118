function ButtonGrid({ times, callback, activeIndex }) {
  return (
    <div className="button-grid">
      {times.map((time, index) => {
        return (
          <Button
            key={time}
            name={time}
            onClick={callback}
            activeIndex={activeIndex}
          ></Button>
        );
      })}
    </div>
  );
}
function Button({ name, onClick, activeIndex }) {
  let css = '';
  if (activeIndex === name) {
    css = 'selected';
  }
  return (
    <button
      type="button"
      className={css}
      onClick={() => {
        onClick(name);
      }}
    >
      {name}
    </button>
  );
}

export default ButtonGrid;
