import React, { useState, useEffect } from 'react';
import StackedForm from '../FormComponents/StackedForm';
import StackingOption from '../FormComponents/Selection/StackingOption';

import { getUserSelection, saveQuestionnaireSelection } from '../../utils/persistence/dataPersistence';
import { openOptionsParcel as openOptionsParcel } from '../../utils/parsers/questionaireParser';

const DoubleSelector = ({ optionsParcel, executeNavigation }) => {
    const [option1Selections, setOption1Selections] = useState([]);
    const [option2Selections, setOption2Selections] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const options = openOptionsParcel(optionsParcel); 
    const option1 = options[0];
    const option2 = options[1];

    useEffect(() => {
        const option1IsValid = !option1.settings?.required || option1Selections.length > 0;
        const option2IsValid = !option2.settings?.required || option2Selections.length > 0;
        setIsButtonDisabled(!(option1IsValid && option2IsValid));
    }, [option1Selections, option2Selections]);

    const onSubmit = (event) => {
        event.preventDefault();
        saveQuestionnaireSelection(option1Selections, option1.id);
        saveQuestionnaireSelection(option2Selections, option2.id);
        executeNavigation();
    };

    const handleSelection = (choiceId, selections, setSelections, isMultiSelect) => {
        if (isMultiSelect) {
            setSelections(prevSelections =>
                prevSelections.includes(choiceId)
                    ? prevSelections.filter(id => id !== choiceId)
                    : [...prevSelections, choiceId]
            );
        } else {
            setSelections([choiceId]);
        }
    };

    const handleSelectionOption1 = (choiceId) => {
        handleSelection(choiceId, option1Selections, setOption1Selections, option1.settings?.multi_select);
    };

    const handleSelectionOption2 = (choiceId) => {
        handleSelection(choiceId, option2Selections, setOption2Selections, option2.settings?.multi_select);
    };

    return (
        <div className="App dark-mode">
            <div className='page-width'>
                <form onSubmit={onSubmit}>
                    <div>
                        <h3>{option1.title}</h3>
                        {option1.choices.map(choice => (
                            <StackingOption
                                key={choice.id}
                                title={choice.title}
                                description={choice.description}
                                price={choice.price}
                                onSelected={() => handleSelectionOption1(choice.id)}
                                isSelected={option1Selections.includes(choice.id)}
                            />
                        ))}
                    </div>
                    <div>
                        <h3>{option2.title}</h3>
                        {option2.choices.map(choice => (
                            <StackingOption
                                key={choice.id}
                                title={choice.title}
                                description={choice.description}
                                price={choice.price}
                                onSelected={() => handleSelectionOption2(choice.id)}
                                isSelected={option2Selections.includes(choice.id)}
                            />
                        ))}
                    </div>
                    <button type="submit" disabled={isButtonDisabled}>Submit</button>
                </form>
            </div>
        </div>
    );
};

export default DoubleSelector;
